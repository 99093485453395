footer {
  background: $white;
  background: linear-gradient(180deg, #ffffffd1 0%, #ffffff 100%);
  position: absolute;
  height: $footer-height;
  bottom: 0;
  right: 0;
  width: 100%;

  @media all and (min-width: 1600px) {
    width: calc(100% - #{$menu-width});
  }
  @media all and (min-width: 1023px) {
    width: calc(100% - #{$menu-width-md});
  }
  .wrap {
    height: 100%;
    display: flex;
    align-items: flex-end;
    align-items: center;
    padding-right: toRem(80px);
    @media all and (max-width: 1600px) {
      padding-right: 3rem;
    }
    @media all and (max-width: 992px) {
      padding-right: 1rem;
      display: flex;
      align-items: center;
      flex-direction: column-reverse;
      flex-wrap: wrap;
      justify-content: center;
    }
  }
  p {
    margin-left: auto;
    padding-right: toRem(37px);
    font-family: $font-header;
    font-weight: 300;
    font-size: toRem(18px);
    @media all and (max-width: 992px) {
      padding-right: 1rem;
    }
    span {
      margin-left: toRem(10px);
      font-size: toRem(13px);
    }
  }
  img {
    padding: 0 toRem(80px) 0 0;
  }
}

