.card {
  position: relative;
  overflow: hidden;
  width: toRem(240px);
  height: toRem(225px);
  display: inline-block;
  margin: $rowspace;
  .wrp {
    max-width: 100%;
    max-height: 100%;
  }
  img {
    position: absolute;
    width: 110%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .ovr {
    position: absolute;
    top: toRem(250px);
    z-index: 2;
    transition: all ease-in-out 0.2s;
    @media all and (max-width: 992px) {
      top: toRem(24px);
    }
  }
  &:hover .ovr {
    bottom: initial;
    top: toRem(24px);
  }
  h2,
  p {
    color: $white;
    text-decoration: none;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

.content > div > div {
  overflow-y: scroll;
}

.img-cont {
  img {
    max-width: calc(100% - #{$article-padding});
    width: 100%;

    @media all and (max-width: 1600px) {
      max-width: calc(100% - #{$article-padding-md});
    }
    @media all and (max-width: 992px) {
      max-width: 100%;
      padding-bottom: 1.5rem
    }
  }
}
