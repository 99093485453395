nav {
  *::before,
  *::after {
    transition: all ease-in-out 0.25s;
  }
  background: $beige;
  padding-left: toRem(113px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media all and (max-width: 1600px) {
    padding-left: $article-padding-md;
  }
  .logo {
    display: block;
    width: toRem(198px);
    max-width: 100%;
    display: flex;
    align-items: flex-end;
    height: toRem(110px);
  }

  .menuwrap {
    display: flex;
    align-items: top;
    height: calc(100% - #{toRem(220px)});
    padding-bottom: toRem(100px);
  }
  ul {
    list-style: none;
    margin-block-start: 0;
    margin-block-end: 0;
    padding-inline-start: 0;
    display: block;
    width: 100%;
    li {
      margin: 0;
      position: relative;
    }
    a {
      display: block;
      color: $black;
      font-family: $font-header;
      font-weight: 300;
      font-size: toRem(20px);
      text-decoration: none;
      padding: toRem(20px) 0;
      transition: font-weight 0.4s ease;
      &::before {
        width: 0;
        height: 0;
        display: block;
        position: absolute;
        overflow: hidden;
        right: toRem(112px);
        content: url("data:image/svg+xml,%3Csvg width='15' height='29' viewBox='0 0 15 29' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M14.18 0V28.8L0 14.4L14.18 0Z' fill='black'/%3E%3C/svg%3E%0A");
      }
      &.active {
        &::before {
          width: 15px;
          height: 33px;
        }
      }

      &:hover::after {
        width: 100%;
      }
      &::after {
        content: "";
        display: block;
        position: absolute;
        width: 0;
        height: toRem(1px);
        background: $white;
        top: toRem(60px);
        transition: width 0.25s ease;
      }
      &.active {
        font-weight: 700;
        &::after {
          width: 100%;
        }
      }
    }
    ul {
      padding-inline-start: toRem(45px);
      height: 0;
      overflow: hidden;

      transition: all 0.4s ease;
      li {
        a {
          font-size: toRem(18px);
        }
      }
    }

    &:hover {
      > a {
        font-weight: 700;
      }
      &::after {
        width: 100%;
      }
    }
  }
  .wchld {
    bottom: initial;
    &.open {
      a::before {
        display: block;
        position: absolute;
        right: toRem(112px);
        content: url("data:image/svg+xml,%3Csvg width='15' height='29' viewBox='0 0 15 29' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M14.18 0V28.8L0 14.4L14.18 0Z' fill='black'/%3E%3C/svg%3E%0A");
        transform: rotate(-90deg);
        width: 15px;
        height: 33px;
      }
      &.active::before {
        transform: rotate(0deg);
      }
      ul {
        height: toRem(245px);
        li a {
          &::before {
            display: none;
          }
        }
      }
    }
    .active {
      a::before {
        display: block;
        position: absolute;
        right: toRem(112px);
        content: url("data:image/svg+xml,%3Csvg width='15' height='29' viewBox='0 0 15 29' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M14.18 0V28.8L0 14.4L14.18 0Z' fill='black'/%3E%3C/svg%3E%0A");
      }
      ~ ul {
        height: toRem(245px);
        li a {
          &::before {
            display: none;
          }
        }
      }
      &::after {
        top: toRem(61px);
      }
    }
  }

  span {
    font-family: $font-header;
    font-weight: 300 !important;
  }
}

.menu-btn {
  display: none;
  @media (max-width: 1023px), (max-height: 750px) {
    display: initial;
  }
  transition: ease all 0.2s;
  height: toRem(60px);
  width: toRem(60px);
  padding: 0 toRem(18px);
  z-index: 3;
  .line {
    max-width: 100%;
    background: $black;
    height: toRem(6px);
    margin-top: 8px;
    transition: ease all 0.2s;
  }
  &.open {
    .line {
      &:nth-last-of-type(1) {
        transform: rotate(-90deg) translate(10px, 10px);
      }
      &:nth-last-of-type(2) {
        transform: rotate(0deg) translate(0px, 13px);
      }
      &:nth-last-of-type(3) {
        transform: rotate(90deg) translate(18px, 10px);
      }
    }
  }
}

@media (max-width: 1023px), (max-height: 750px) {
  nav {
    position: absolute;
    top: 0;
    padding: 1rem 2rem;
    width: 100%;

    
    transition: max-height ease 0.2s;
    max-height: $mobmenu-height-closed;
    overflow: hidden;
    .logo-wrap {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: space-between;
    }
    &.open {
      max-height: $mobmenu-height-open;
      &.sub {
        max-height: $mobmenu-height-sub-open;
      }
    }
    .logo {
      height: initial;
    }
    .menuwrap {
      align-items: flex-start;
      flex-basis: 100%;
      max-width: 100%;
      height: 0;
      overflow: hidden;
      padding: 0;
      &.open {
        height: toRem(80px);
        &.sub-open {
          height: toRem(192px);
        }
        ul a {
          padding: 0.5rem;
          font-size: 1rem;
          &::after {
            top: 2rem;
          }
          &::before {
            display: none;
          }
        }
        .wchld {
          &.open ul {
            height: $mobmenu-inner-height;
          }
          .active {
            ~ ul {
              height: $mobmenu-inner-height;
            }
            &::after {
              top: 2rem;
            }
          }
        }
      }
    }
    ul li ul {
      padding-inline-start: 1rem;
    }
  }
}
