.kapcsolat {
  section {
    padding: toRem(110px);
    @media all and (max-width: 992px) {
      padding: 4rem 1rem;
    }
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
    height: 100%;
  }
  td {
    font-family: Roboto Mono;
    font-style: normal;
    font-weight: normal;
    font-size: toRem(16px);
    padding: toRem(33px) toRem(33px) 0 0;
    &.title {
      font-style: italic;
    }
    &.name {
      font-weight: bold;
      font-size: toRem(20px);
    }
  }
}
