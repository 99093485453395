@import url("https://fonts.googleapis.com/css2?family=Sen:wght@400;700;800&display=swap&subset=latin-ext");
@import url("https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@300;400;700&display=swap&subset=latin-ext");

$font-header: "Roboto Mono", monospace;
$font-default: "Sen", "HelveticaNeue-Light", "Helvetica Neue Light",
  "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;

body {
  font-family: $font-default;
  font-weight: normal;
  font-size: toRem(14px);
  text-align: left;
  margin: 0;
  transition: all 0.3s ease 0s;
  background: $white;
}

.text {
  line-height: toRem(27px);
}

.article {
  h2,
  h1 {
    margin-top: 0;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $font-header;
  margin-bottom: toRem(30px);
}

.excerpt {
  font-family: $font-header;
  font-style: italic;
  margin-bottom: toRem(25px);
}
