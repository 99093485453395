.sprite {
  width: 240px;
  height: 226px;
  margin: 0.5em;
}

.svg-wrapper {
  width: 240px;
  height: 226px;
  background: $white;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  svg {
    z-index: 2;
    position: absolute;
    left: 0;
    top: 0;
    width: 226px;
    position: absolute;
    left: 0px;
    top: -13px;
    width: 241px;
    height: 240px;
  }
}
