@import "~grid-simpleflex";

* {
  box-sizing: border-box;
}

[class*="col-md-"] {
  padding: 0;
}

.row {
  margin-right: 0;
  margin-left: 0;
}

body,
a {
  cursor: url("data:image/svg+xml,%3Csvg width='51' height='44' viewBox='0 0 51 44' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M49.9749 30.5599C50.0232 30.4634 49.999 30.3429 49.9266 30.2625L25.411 3.12402C25.3949 3.10795 25.3788 3.09187 25.3627 3.08384C25.3466 3.06777 25.3225 3.05973 25.3064 3.05169C25.2984 3.05169 25.2984 3.05169 25.2903 3.05169C25.2742 3.04366 25.2581 3.04366 25.242 3.04366L6.96195 1.00244C6.84126 0.986371 6.73667 1.05066 6.68035 1.16317L1.02414 13.4346C1.01609 13.4587 1.00805 13.4747 1 13.4988C1 13.5069 1 13.5069 1 13.5149C1 13.531 1 13.5551 1 13.5712C1 13.5792 1 13.5872 1 13.5953C1 13.6114 1.00805 13.6274 1.01609 13.6435C1.01609 13.6515 1.02414 13.6596 1.02414 13.6676C1.02414 13.6756 1.02414 13.6756 1.03218 13.6837C1.04023 13.6917 1.04827 13.6998 1.04827 13.7078C1.05632 13.7158 1.05632 13.7239 1.06437 13.7319L25.588 40.8704C25.6122 40.8945 25.6443 40.9186 25.6765 40.9347C25.7007 40.9508 25.7328 40.9507 25.757 40.9588L44.029 43C44.0371 43 44.0451 43 44.0612 43C44.0773 43 44.0853 43 44.1014 43H44.1095C44.1175 43 44.1256 42.992 44.1336 42.992C44.1497 42.9839 44.1658 42.9839 44.1819 42.9759C44.1899 42.9759 44.198 42.9679 44.206 42.9598C44.2221 42.9518 44.2302 42.9437 44.2463 42.9277C44.2543 42.9196 44.2624 42.9196 44.2624 42.9116C44.2784 42.8955 44.2865 42.8795 44.2945 42.8634C44.2945 42.8553 44.3026 42.8553 44.3026 42.8473C44.3026 42.8473 44.3026 42.8473 44.3026 42.8393C44.3026 42.8393 44.3026 42.8393 44.3026 42.8312L49.9749 30.5599ZM25.9259 40.4284L1.95745 13.9007L19.4169 15.8454L43.3854 42.3732L25.9259 40.4284ZM1.68389 13.322L7.09873 1.56498L24.7995 3.54191L23.2386 6.92518L19.3847 15.2909L5.98036 13.7962L1.68389 13.322ZM43.9968 42.2366L19.8674 15.5401L25.2823 3.79907L49.4036 30.4956L43.9968 42.2366Z' fill='black' stroke='black' stroke-width='0.25' stroke-miterlimit='10'/%3E%3C/svg%3E%0A")
      16 16,
    pointer;
}

#root {
  overflow: hidden;
  height: 100vh;
  position: relative;
}

nav {
  height: 100vh;
  width: $menu-width;

  z-index: 2;
  position: absolute;

  @media all and (max-width: 1600px) {
    width: $menu-width-md;
  }
}

.content {
  width: calc(100% - #{$menu-width});
  height: 100%;
  float: right;
  .article {
    padding-bottom: 8rem;
    &.two {
      .text {
        padding-right: 6.25rem;
        @media (max-width: 1023px), (max-height: 750px) {
          padding-right: 0;

        }
      }
    }
  }
  @media all and (max-width: 1600px) {
    width: calc(100% - #{$menu-width-md});
  }
}

.spacer,
.pro,
.article {
  padding: $article-padding;
  @media all and (max-width: 1600px) {
    padding: 4rem 3rem;
  }
  @media all and (max-width: 992px) {
    padding: 4rem 1rem;
  }
}

.kapcsolat {
  padding-bottom: 8rem;
}

.pro {
  text-align: center;
}

@media all and (min-width: 1400px) {
  .col-md-25 {
    flex-basis: 20%;
    max-width: 20%;
  }
}

@media (max-width: 1023px) {
  #root {
    display: block;
    position: relative;
  }

  .content {
    overflow-y: scroll;
    height: 100%;
    width: 100%;
    padding-top: 6rem;
  }

  footer {
    width: 100%;
  }
}
