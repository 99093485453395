$white: #ffffff;
$grey: #c4c4c4;
$black: #000000;
$beige: #d7d2cb;

$rowspace: toRem(5px);

$menu-width: toRem(430px);
$menu-width-md: toRem(390px);

$mobmenu-height-closed: toRem(92px);

$mobmenu-height-open: 14rem;
$mobmenu-height-sub-open: 21rem;


$mobmenu-sub-height-open: toRem(390px);
$mobmenu-sub-height-closed: toRem(390px);
$mobmenu-sub-inner-height-open: toRem(390px);
$mobmenu-sub-inner-height-closed: toRem(390px);

$mobmenu-inner-height: 9rem;


$footer-height: toRem(100px);

$article-padding: toRem(100px);
$article-padding-md: 3rem;