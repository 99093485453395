// X 29 blocks
// Y 18 blocks

// default X: 1305px;
// default Y: 720px;

// all margins X 43px
// all margins Y 16px

table.map {
  max-width: 100%;
  margin: 0 auto;
  td {
    padding: 5px;
    height: 10px;
    width: 10px;
    border: none;

    @media all and (min-width: 992px) {
      padding: 9px;

      height: 18px;
      width: 18px;
    }
    @media all and (min-width: 1149px) {
      padding: 11px;

      height: 21px;
      width: 21px;
    }
    @media all and (min-width: 1249px) {
      padding: 13px;

      height: 26px;
      width: 26px;
    }
    @media all and (min-width: 1449px) {
      padding: 16px;

      height: 32px;
      width: 32px;
    }
    @media all and (min-width: 1600px) {
      padding: 17px;

      height: 34px;
      width: 34px;
    }
  }
  .hu {
    background-image: url("../Assets/map-a.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    &.ott {
      background: url("../Assets/map-b.png");
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      position: relative;
      padding: 0;
      height: inherit;
      a {
        display: block;
        height: 10px;
        width: 10px;
        border: none;

        @media all and (min-width: 992px) {
          height: 18px;
          width: 18px;
        }
        @media all and (min-width: 1149px) {
          height: 21px;
          width: 21px;
        }
        @media all and (min-width: 1249px) {
          height: 26px;
          width: 26px;
        }
        @media all and (min-width: 1449px) {
          height: 32px;
          width: 32px;
        }
        @media all and (min-width: 1600px) {
          height: 34px;
          width: 34px;
        }
      }
    }
  }
}
